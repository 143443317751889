import { useDispatch, useSelector } from 'react-redux';
import { getDealerInfo, getTireChangeParametersOfAllDealers } from '../services/dataService';
import { getDateFromString, redirectToService, getUrlQueryParams } from './helperFunctions';
import { useIntl } from 'react-intl';

export const useDealer = () => {
    const { queryParameter } = useSelector(state => state.queryParameter);
    const intl = useIntl();
    const countryCode = intl.locale.split('-')[1];
    const dispatch = useDispatch();

    const getDealerInfoAsync = async (dealerIds) => {
        dispatch({ type: 'dealersSearching', payload: true });

        const dealersFromBackend = await getDealerInfo(dealerIds, countryCode);

        let queryParams = getUrlQueryParams();

        if (!queryParams) queryParams = queryParameter;

        if (dealersFromBackend && dealersFromBackend.length > 0) {
            const dealersWithWeekSchedule = await getDealersWithWeekSchedule(dealersFromBackend);

            if (dealersWithWeekSchedule && dealersWithWeekSchedule.length > 0) {
                dispatch({ type: 'hasDealersWithTireChange', payload: true });
                dispatch({ type: 'dealerListAdded', payload: dealersWithWeekSchedule });
            } else {
                redirectToService(queryParams);
            }
        } else {
            redirectToService(queryParams);
        }

        dispatch({ type: 'dealersSearching', payload: false });
    };

    const dealersWithEnabledTireChangeExist = (tireChangeParameters) => {
        if (tireChangeParameters && tireChangeParameters.length > 0) {
            return tireChangeParameters.some((p) => {
                if (p.StartDate && p.EndDate) {
                    const startDate = getDateFromString(p.StartDate);
                    const endDate = getDateFromString(p.EndDate);
                    const today = new Date();

                    return startDate <= today && endDate >= today;
                }
                else if (p.StartDate && !p.EndDate) {
                    const startDate = getDateFromString(p.StartDate);
                    const today = new Date();

                    return startDate <= today;
                }

                return false;
            });
        }

        return false;
    };

    const getDealersWithWeekSchedule = async (dealersFromBackend) => {
        let dealersWithWeekSchedule = [];

        const dealerIds = dealersFromBackend.map(dealer => dealer.DealerId);
        const dealerTireChangeParameters = await getTireChangeParametersOfAllDealers(dealerIds);

        if (!dealersWithEnabledTireChangeExist(dealerTireChangeParameters)) redirectToService(queryParameter);

        if (dealerTireChangeParameters) {
            dealerTireChangeParameters.forEach(dealerParams => {
                if ((dealerParams.StartDate && getDateFromString(dealerParams.StartDate) <= Date.now()) &&
                    (!dealerParams.EndDate || getDateFromString(dealerParams.EndDate) >= Date.now())) {
                    const dealerObj = dealersFromBackend.find(d => d.DealerId === dealerParams.DealerId);

                    if (dealerObj) {
                        dealerObj.WeekSchedule = dealerTireChangeParameters.find(d => d.DealerId === dealerObj.DealerId);

                        dealersWithWeekSchedule.push(dealerObj);
                    }
                }
            });
        }

        return dealersWithWeekSchedule;
    };

    return { getDealerInfoAsync };
};