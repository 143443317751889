import React from 'react';

export const createTimeSlotsOfSelectedDay = (date, currentDealer, availableServiceTimes) => {
    console.log('date ', date);

    let timeSlots = [];
    const times = currentDealer.WeekSchedule;

    switch (date.getDay()) {
        case 0:
            timeSlots = createTimeSlots(times.SundayStart, times.SundayEnd, date, currentDealer, availableServiceTimes);
            break;
        case 1:
            timeSlots = createTimeSlots(times.MondayStart, times.MondayEnd, date, currentDealer, availableServiceTimes);
            break;
        case 2:
            timeSlots = createTimeSlots(times.TuesdayStart, times.TuesdayEnd, date, currentDealer, availableServiceTimes);
            break;
        case 3:
            timeSlots = createTimeSlots(times.WednsdayStart, times.WednsdayEnd, date, currentDealer, availableServiceTimes);
            break;
        case 4:
            timeSlots = createTimeSlots(times.ThursdayStart, times.ThursdayEnd, date, currentDealer, availableServiceTimes);
            break;
        case 5:
            timeSlots = createTimeSlots(times.FridayStart, times.FridayEnd, date, currentDealer, availableServiceTimes);
            break;
        case 6:
            timeSlots = createTimeSlots(times.SaturdayStart, times.SaturdayEnd, date, currentDealer, availableServiceTimes);
            break;
        default:

    }

    return timeSlots;

};

function createTimeSlots(startTime, endTime, date, currentDealer, availableServiceTimes) {

    if (!startTime || !endTime) return null;

    startTime = startTime.trim();
    endTime = endTime.trim();
    const timeSlots = [];
    const startMinutes = convertClockTimeToMinutes(startTime);
    const endMinutes = convertClockTimeToMinutes(endTime);

    if (isNaN(currentDealer.WeekSchedule.PeriodLength)) {
        console.log('Illegal number format in time slots creation, PeriodLength: ', currentDealer.WeekSchedule.PeriodLength);
        return null;
    }
    const periodLengthInMinutes = parseInt(currentDealer.WeekSchedule.PeriodLength);

    for (let i = startMinutes; i <= endMinutes; i += periodLengthInMinutes) {

        const hours = Math.floor(i / 60);
        const minutes = i % 60;

        const hourString = hours < 10 ? '0' + hours : hours;
        const minuteString = minutes < 10 ? '0' + minutes : minutes;

        const slotValue = hourString + ':' + minuteString;

        const availableTimeSlot = timeSlotIfAvailable(slotValue, date, availableServiceTimes);

        if (!availableTimeSlot) {
            timeSlots.push(
                {
                    StartTime: slotValue,
                    hidden: true
                });
        } else {
            timeSlots.push(availableTimeSlot);
        }
    }

    return timeSlots;

}

export const convertClockTimeToMinutes = (clockTime) => {

    let clockTimeInMinutes = 0;

    try {
        const clockTimeHours = parseInt(clockTime.substr(0, clockTime.length - 3));
        clockTimeInMinutes = 60 * clockTimeHours + parseInt(clockTime.substr(clockTime.length - 2, 2));
        //console.log('clockTimeInMinutes: ', clockTimeInMinutes);

    } catch (ex) {
        console.log('invalid clock time: ', ex);
    }
    return clockTimeInMinutes;
};

function timeSlotIfAvailable(timeSlot, currentDate, availableServiceTimes) {

    let availableTimeSlot = null;

    availableServiceTimes.forEach((item) => {
        const itemDate = parseDateFromDateTimeString(item.Date);

        if (currentDate.getDate() === itemDate.getDate() &&
            currentDate.getMonth() === itemDate.getMonth() &&
            currentDate.getFullYear() === itemDate.getFullYear()) {

            item.ServiceTimes.forEach((slot) => {
                if ((slot.StartTime === timeSlot) && !slot.Locked) {
                    //console.log('free slot ', slot);
                    availableTimeSlot = slot;
                }
            });
        }
    });
    return availableTimeSlot;
}

export const disabledDays = (availableServiceTimes, currentDealer) => {

    const disabledDays = [];
    const startDate = parseDateFromDateTimeString(currentDealer.WeekSchedule.StartDate);
    const endDate = parseDateFromDateTimeString(currentDealer.WeekSchedule.EndDate);

    let disabledDay = new Date(startDate.toDateString());

    for (let i = 0; disabledDay <= endDate; i++) {

        disabledDay = new Date(startDate.toDateString());
        disabledDay.setDate(startDate.getDate() + i);

        if (!availableServiceTimes || !availableServiceTimes.some(item => parseDateFromDateTimeString(item.Date).getDate() === disabledDay.getDate() &&
            parseDateFromDateTimeString(item.Date).getMonth() === disabledDay.getMonth() &&
            parseDateFromDateTimeString(item.Date).getFullYear() === disabledDay.getFullYear())) {

            disabledDays.push(disabledDay);
        }

    }

    return disabledDays;
};

export const parseDateFromDateTimeString = (dateTimeString, buffer = 0) => {

    const itemDateParts = dateTimeString.split('.');

    let date = new Date(parseInt(itemDateParts[2].substr(0, 4)),
        parseInt(itemDateParts[1]) - 1,
        parseInt(itemDateParts[0]) + buffer);

    return date;
};

export default {
    createTimeSlotsOfSelectedDay
};