import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DayPicker, { DateUtils } from 'react-day-picker';
//import 'react-day-picker/lib/style.css';
import { getServiceTimes, getAvailableServiceTimes } from '../../services/dataService';
import { disabledDays, parseDateFromDateTimeString, createTimeSlotsOfSelectedDay } from '../../services/serviceTimeService';
import { FormattedMessage } from 'react-intl';
import LoaderSpinner from '../LoaderSpinner/LoaderSpinner';


const DateSelection = ({ daysOfWeekLong, daysOfWeekShort, months }) => {
    const dispatch = useDispatch();
    const serviceTime = useSelector(state => state.serviceTime);
    const selectedTimeSlot = useSelector(state => state.timeSlot);
    const dealers = useSelector(state => state.dealer);
    const carDetails = useSelector(state => state.carDetails);
    const services = useSelector(state => state.services);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (dealers.isReturnFromSummaryPage) {
            setLoading(false);
            return;
        }

        async function fetchAvailableServiceTimes(dealerId, license, numberOfWeeks, duration, timeSlot, managementSystem, calendarBuffer, startHoursMinutes) {
            if (dealerId !== 0) {

                const availableServiceTimes = await getAvailableServiceTimes(dealerId, license, numberOfWeeks, duration, timeSlot, managementSystem, calendarBuffer, startHoursMinutes);
                console.log('available service times: ', availableServiceTimes);

                const disabledDates = disabledDays(availableServiceTimes, dealers.currentDealer);

                dispatch({
                    type: 'availableServiceTimesAdded',
                    payload: availableServiceTimes
                });

                dispatch({
                    type: 'reservedTimeUpdated',
                    payload: null
                });

                dispatch({
                    type: 'dateUnselected'
                });

                dispatch({
                    type: 'datesDisabled',
                    payload: disabledDates
                });

                if (selectedTimeSlot.selectedTimeSlot) {
                    dispatch({
                        type: 'timeSlotUnselected',
                        payload: selectedTimeSlot.selectedTimeSlot
                    });
                }

                dispatch({
                    type: 'timeSlotsDeleted'
                });
            } else {
                dispatch({
                    type: 'availableServiceTimesDeleted'
                });
            }

            setLoading(false);
        }

        console.log('dealers.currentDealer ', dealers.currentDealer);

        if (dealers.currentDealer && dealers.currentDealer.WeekSchedule) {

            const weekSchedule = dealers.currentDealer.WeekSchedule;

            const startHoursMinutes = [
                weekSchedule.SundayStart, weekSchedule.MondayStart, weekSchedule.TuesdayStart,
                weekSchedule.WednsdayStart, weekSchedule.ThursdayStart, weekSchedule.FridayStart,
                weekSchedule.SaturdayStart
            ];

            console.log('startHoursMinutes', startHoursMinutes);

            fetchAvailableServiceTimes(dealers.currentDealer.DealerId,
                carDetails.license.value,
                parseInt(weekSchedule.WeekSum),
                services.totalDuration / 12, // total services duration in hours
                parseInt(weekSchedule.PeriodLength),
                dealers.currentDealer.ManagementSystem,
                weekSchedule.Buffer,
                startHoursMinutes);
        }
    },[dealers.currentDealer, services.totalDuration]);


    const SelectReservationDate = (date, disabled) => {
        if (disabled.disabled === true) {
            return;
        }
        console.log('selectedDate ', date);

        dispatch({
            type: 'dateSelected',
            payload: date
        });

        changeTimeSlots(date);
    };

    const changeTimeSlots = (date) => {
        if (dealers.currentDealer.WeekSchedule && serviceTime.availableServiceTimes) {
            const timeSlots = createTimeSlotsOfSelectedDay(date, dealers.currentDealer, serviceTime.availableServiceTimes);

            dispatch({
                type: 'timeSlotsAdded',
                payload: timeSlots
            });

        } else {
            dispatch({
                type: 'timeSlotsDeleted'
            });
        }

        if (selectedTimeSlot.selectedTimeSlot) {
            dispatch({
                type: 'timeSlotUnselected',
                payload: selectedTimeSlot.selectedTimeSlot
            });
        }
    };

    if (loading) return (
        <div>
            <LoaderSpinner />
        </div>
    );

    return (
        <span>
            <div className="calendar-header"><FormattedMessage id="dateSelection.calendarHeader" /></div>
            <DayPicker
                weekdaysShort={daysOfWeekShort}
                weekdaysLong={daysOfWeekLong}
                months={months}
                firstDayOfWeek={1}
                selectedDays={serviceTime.selectedDate}
                onDayClick={SelectReservationDate}
                tabIndex='0'
                fixedWeeks={true}
                disabledDays={
                    [
                        { before: dealers.currentDealer && dealers.currentDealer.WeekSchedule ? parseDateFromDateTimeString(dealers.currentDealer.WeekSchedule.StartDate, parseInt(dealers.currentDealer.WeekSchedule.Buffer)) : new Date() },
                        { after: dealers.currentDealer && dealers.currentDealer.WeekSchedule ? parseDateFromDateTimeString(dealers.currentDealer.WeekSchedule.EndDate) : new Date() },
                        ... (dealers.currentDealer && dealers.currentDealer.WeekSchedule ? serviceTime.disabledDates : [new Date()])
                    ]
                }
            />
        </span>
    );
}

export default DateSelection;