
const initialState = {
    services: [],
    additionalServices: [],
    totalDuration: 0
};


const servicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'RESET_STORE':
            return initialState;

        case 'servicesListAdded':
            return {
                totalDuration: 0,
                services: action.payload.services.map(
                    (service) =>
                        (service.Selected) ?
                            { ...service, Selected: null } :
                            { ...service, Selected: false }
                ),
                additionalServices: action.payload.additionalServices.map(
                    (service) =>
                        (service.Selected) ?
                            { ...service, Selected: null } :
                            { ...service, Selected: false }
                )
            }
        case 'servicesListDeleted':
            return {
                services: [],
                additionalServices: [],
                totalDuration: 0
            }
        case 'serviceSelected':
            return {
                ...state,
                totalDuration: state.totalDuration + serviceDurationChange(action.payload.ServiceId, state.services),
                services: state.services.map(
                    (service) =>
                        (service.ServiceId === action.payload.ServiceId) ?
                            { ...service, Selected: true } :
                            { ...service, Selected: false }

                )
            }
        case 'additionalServiceSelected':
            return {
                ...state,
                totalDuration: state.totalDuration + additionalServiceDurationChange(action.payload.ServiceId, state.additionalServices),
                additionalServices: state.additionalServices.map(
                    (service) =>
                        (service.ServiceId === action.payload.ServiceId) ?
                            { ...service, Selected: !service.Selected } :
                            service
                )
            }

        default:
            return state;
    }

    function additionalServiceDurationChange(serviceId, services) {
        let duration = 0;
        services.map(service => {
            if (service.ServiceId === serviceId) {
                duration = service.Selected ? - parseInt(service.Duration) : parseInt(service.Duration);
            }
        });

        return duration;
    }

    function serviceDurationChange(serviceId, services) {
        let duration = 0;
        services.map(service => {
            if (service.ServiceId === serviceId) {
                duration += parseInt(service.Duration);
            } else {
                if (service.Selected) {
                    duration -= parseInt(service.Duration);
                }
            }
        });

        return duration;
    }
}

export default servicesReducer;