import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDealer } from '../../functions/dealerFunctions';
import { FormattedMessage } from 'react-intl';

const DealerSelection = () => {
    const { getDealerInfoAsync } = useDealer();
    const dispatch = useDispatch();
    const dealersInfo = useSelector(state => state.dealer);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const dealerIdsFromUrl = queryParams.get('Dealers');

        if (dealerIdsFromUrl) {
            getDealerInfoAsync(dealerIdsFromUrl);
        }
    }, []);

    const selectDealer = async (dealer) => {
        dispatch({
            type: 'isReturnFromSummaryPage', payload: false
        });

        dispatch({
            type: 'currentDealerUpdated', payload: dealer
        });

        dispatch({
            type: 'dateUnselected'
        });

        dispatch({
            type: 'timeSlotUnselected'
        });

        dispatch({
            type: 'timeSlotsDeleted'
        });
    };

    return (
        <section id="service-point" className="margin-top-m" >
            <div className="container">
                <div className="row d-flex">
                    <div className="col-12 col-lg-10 offset-lg-1 bg-w padding-top-l-scale padding-bottom-m-md-up padding-hor-l-scale">
                        <h2 className="visual-h3">1. <FormattedMessage id="dealerSelection.header" /></h2>
                        {
                            !dealersInfo.dealers || dealersInfo.dealers.length === 0 &&
                            <div>
                                <FormattedMessage id="dealerSelection.message.noDealersAvailable" />
                            </div>
                        }
                        {
                            dealersInfo.currentDealer != null &&
                            <ul className="service-point-details">
                                <li>{dealersInfo.currentDealer.DealerName}</li>
                                <li>{dealersInfo.currentDealer.StreetAddress}</li>
                                <li>{dealersInfo.currentDealer.ZipCode} {dealersInfo.currentDealer.City}</li>
                                <li><FormattedMessage id="dealerSelection.telephone" /> {dealersInfo.currentDealer.PhoneNo}</li>
                                <li><a href={"mailto:" + dealersInfo.currentDealer.ReservationHandlers}>{dealersInfo.currentDealer.ReservationHandlers}</a></li>
                            </ul>
                        }
                        {
                            dealersInfo.dealers.length > 1 &&
                            <div className="dropdown">
                                <button className="bmw-btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" autoFocus>
                                    {
                                        dealersInfo.currentDealer &&
                                        <span>
                                            {dealersInfo.currentDealer.OfficeName}
                                            {
                                                dealersInfo.currentDealer.City &&
                                                ', ' + dealersInfo.currentDealer.City
                                            }
                                        </span>
                                        ||
                                        <FormattedMessage id="dealerSelection.selectDealer" />
                                    }
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    {
                                        dealersInfo.dealers.map((dealer, index) =>
                                            <li key={index} onKeyPress={(event) => selectDealer(dealer)} onClick={(event) => selectDealer(dealer)} name={dealer.OfficeName} tabIndex="0">
                                                <div className="dropdown-item">{dealer.OfficeName}, {dealer.City}</div>
                                            </li>
                                        )}
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section >
    );
};

export default DealerSelection;