
const initialState = {
    dealers: [],
    currentDealer: null,
    //{
    //    DealerId: 0,
    //    DealerName: 'Välj serviceverkstad',
    //    StreetAddress: null,
    //    ZipCode: null,
    //    City: null,
    //    PhoneNo: null,
    //    Email: null,
    //    WeekSchedule: null
    //},
    isReturnFromSummaryPage: false,
    dealersSearching: false,
    hasDealersWithTireChange: false
};


const dealerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'RESET_STORE':
            return initialState;

        case 'dealerListAdded':
            if (action.payload && action.payload.length === 1) {
                return {
                    ...state, dealers: action.payload, currentDealer: action.payload[0]
                }
            }
            return {
                ...state, dealers: action.payload, currentDealer: null
            }

        case 'currentDealerUpdated':
            return {
                ...state, currentDealer: action.payload
            }

        case 'isReturnFromSummaryPage':
            return {
                ...state, isReturnFromSummaryPage: action.payload
            }

        case 'dealersSearching':
            return {
                ...state, dealersSearching: action.payload
            }

        case 'hasDealersWithTireChange':
            return {
                ...state, hasDealersWithTireChange: action.payload
            }

        default:
            return state;
    }
};

export default dealerReducer;