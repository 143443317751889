import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getServices } from '../../services/dataService';
import { FormattedMessage, useIntl } from 'react-intl';


const ServicesSelection = () => {

    const dispatch = useDispatch();
    const carDetails = useSelector(state => state.carDetails);
    const services = useSelector(state => state.services);
    const dealers = useSelector(state => state.dealer);
    const language = useSelector(state => state.language);
    const intl = useIntl();

    let showSectionData = carDetails.vehicleFromBackend && dealers.currentDealer;

    const browserCulture = document.documentElement.dataset.culture;

    const currencyUnit = (browserCulture === 'sv-SE' || browserCulture === 'nb-NO' || browserCulture === 'da-DK') ? " kr" : " €";

    const bookingLanguage = language.locale ? language.locale.split('-')[0] : browserCulture.split('-')[0];

    useEffect(() => {
        if (dealers.isReturnFromSummaryPage) {
            return;
        }

        async function fetchServices(dealerId) {
            if (dealerId !== 0) {
                const servicesFromBackend = await getServices(dealerId);
                console.log('servicesFromBackend', servicesFromBackend);

                dispatch({
                    type: 'servicesListAdded',
                    payload: servicesFromBackend
                });
            } else {
                dispatch({
                    type: 'servicesListDeleted'
                });
            }
        }

        if (dealers.currentDealer) {
            fetchServices(dealers.currentDealer.DealerId);
        }
    },[dealers.currentDealer]);

    const selectService = (service, e) => {

        dispatch({
            type: 'isReturnFromSummaryPage', payload: false
        });

        dispatch({
            type: 'serviceSelected', payload: service
        });
    }

    const selectAdditionalService = (service, e) => {

        dispatch({
            type: 'isReturnFromSummaryPage', payload: false
        });

        dispatch({
            type: 'additionalServiceSelected', payload: service
        });
    }

    const getTyreServiceNameTranslation = (service) => {
        let translation = service.Name;

        if (browserCulture === "fi-FI") {
            if (bookingLanguage === "sv" && service.Name2) translation = service.Name2;
            else if (bookingLanguage === "en" && service.Name3) translation = service.Name3;
        }
        else if (browserCulture !== "en-GB" && bookingLanguage === "en" && service.Name2) {
            translation = service.Name2;
        }

        return translation;
    };

    const getTyreServiceDescriptionTranslation = (service) => {
        let translation = service.Description;

        if (browserCulture === "fi-FI") {
            if (bookingLanguage === "sv" && service.Description2) translation = service.Description2;
            else if (bookingLanguage === "en" && service.Description3) translation = service.Description3;
        }
        else if (browserCulture !== "en-GB" && bookingLanguage === "en" && service.Description2) {
            translation = service.Description2;
        }

        return translation;
    };

    function formatDecimalNumber(amount, varyingPrice) {
        if ((typeof varyingPrice !== 'undefined') && varyingPrice && amount < 0)
            return (intl.formatMessage({ id: "servicesSelection.varyingPrice" }));
        else
            return Math.round(amount);
    }

    return (
        <section id="services" className="margin-top-m">
            <div className="container">
                <div className="row d-flex">
                    <div className="col-12 col-lg-10 offset-lg-1 bg-w padding-top-m-scale padding-hor-l-scale hr-top">
                        <h2 className="visual-h3">3. <FormattedMessage id="servicesSelection.header" /></h2>
                        {
                            !showSectionData &&
                            <FormattedMessage id="servicesSelection.message.giveCarDetailsFirst" />
                            ||
                            <div>
                                <ul className="services-list">
                                    {
                                        services && services.services && services.services.map((service, index) =>
                                            <li key={index}>
                                                <div className="bmw-tyre-service">
                                                    <div className="bmw-radiobutton tyre-service-selection">
                                                        <input type="radio" id={'tire-change' + index} onChange={(event) => selectService(service, event)} name='tire-change' checked={service.Selected} />
                                                        <label htmlFor={'tire-change' + index}>{getTyreServiceNameTranslation(service)}</label>
                                                        <div className="service-description">
                                                            {getTyreServiceDescriptionTranslation(service)}
                                                        </div>
                                                    </div>
                                                    <div className="tyre-service-info">
                                                        {service.ImageId ?
                                                            <img className="tyre-service-image" src={`/tirechange/getimage/${service.ImageId}`} /> :
                                                            null
                                                        }
                                                        <span>
                                                            {formatDecimalNumber(service.PriceVAT)}
                                                            {currencyUnit}
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                </ul>
                                <h3 className="visual-h4"><FormattedMessage id="servicesSelection.additionalServices.header" /></h3>
                                <ul className="services-list">
                                    {
                                        services && services.additionalServices && services.additionalServices.map((addservice, index) =>
                                            <li key={index}>
                                                <div className="bmw-tyre-service">
                                                    <div className="bmw-checkbox tyre-service-selection">
                                                        <input type="checkbox" id={'car-wash' + index} onChange={(event) => selectAdditionalService(addservice, event)} checked={addservice.Selected} />
                                                        <label htmlFor={'car-wash' + index}>{getTyreServiceNameTranslation(addservice)}</label>
                                                        <div className="service-description">
                                                            {getTyreServiceDescriptionTranslation(addservice)}
                                                        </div>
                                                    </div>
                                                    <div className="tyre-service-info">
                                                        {addservice.ImageId ?
                                                            <img className="tyre-service-image" src={`/tirechange/getimage/${addservice.ImageId}`} /> :
                                                            null
                                                        }
                                                        <span>
                                                            {formatDecimalNumber(addservice.PriceVAT, true)}
                                                            {currencyUnit}
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );

}

export default ServicesSelection;