export const getDateFromString = (dateString) => {
    if (dateString) {
        const dateParts = dateString.split(' ')[0].split('.');
        return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    }
};

export const redirectToService = (queryParams) => {
    window.location.href = window.location.origin + `/service${queryParams}`;
};

export const getUrlQueryParams = () => {
    const queryParamsFromUrl = new URLSearchParams(window.location.search);

    const dealersFromUrl = queryParamsFromUrl.get('Dealers') || queryParamsFromUrl.get('dealers');
    const licenseFromUrl = queryParamsFromUrl.get('License') || queryParamsFromUrl.get('license');
    const mileageFromUrl = queryParamsFromUrl.get('Mileage') || queryParamsFromUrl.get('mileage');

    if (!dealersFromUrl && !licenseFromUrl && !mileageFromUrl) return null;

    let queryParams = '?';

    if (dealersFromUrl) queryParams += `Dealers=${dealersFromUrl}`;
    if (licenseFromUrl) queryParams += `&license=${licenseFromUrl}`;
    if (mileageFromUrl) queryParams += `&mileage=${mileageFromUrl}`;

    return queryParams;
};